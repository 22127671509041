<template>
  <Layout :hbgsrc="bg">
    <template slot="body">
      <MainSlogan>
        <template slot="title">公司简介</template>
        <template slot="p1">"用科技照亮能源之路，以信誉启动零碳之行"</template>
        <div class="tips" v-if="$store.state.isPc">
          <p>
            誉照科技作为零碳综合服务商，积极布局以光电、风电、充电和储能为核心的智慧能源新生态。<br />誉照秉承科技创新、低碳生态、品质生活三大理念，在世界范围内应用并推广清洁能，建立和完善符合生态文明并可持续的能源产业生态。
            <br />
            基于云、人工智能等新技术将数据深度融合，构建以智能物联为基点，助推绿色能源新格局的转型。以零碳为核心，创造更加舒适安全节能<br />
            高效的零碳品质生活。
          </p>
        </div>
        <div class="tips" v-if="!$store.state.isPc">
          <p>
            誉照科技作为零碳综合服务商，积极布局以光电、风电、充电和储能为核心的智慧能源新生态。
          </p>
          <p>
            誉照秉承科技创新、低碳生态、品质生活三大理念，在世界范围内应用并推广清洁能源，建立和完善符合生态文明并可持续的能源产业生态。基于云、人工智能等新技术将数据深度融合，构建以智能物联为基点，助推绿色能源新格局的转型。以零碳为核心，创造更加舒适安全、节能高效的零碳品质生活。
          </p>
        </div>
      </MainSlogan>
      <!-- 愿景 -->
      <section class="wish">
        <div class="box flex">
          <p class="left">愿景和使命</p>
          <!-- <div class="line"></div> -->
          <div class="right flex">
            <span>以人为本 科技赋能</span>
            <p v-if="$store.state.isPc">
              利用科技创新推助能源转型<br />
              致力打造并完善低碳生态新格局<br />
              构建零碳美好品质生活
            </p>
            <p v-if="!$store.state.isPc">
              科技推助能源转型<br />创新低碳生态服务<br />构建零碳美好生活<br />
            </p>
          </div>
        </div>
      </section>
      <template v-if="!$store.state.isPc">
        <section class="one">
          <el-image :src="one1" fit="cover"></el-image>
        </section>
        <section class="two">
          <h3>业务架构</h3>
          <div class="img flex">
            <el-image :src="one2" fit="cover"></el-image>
          </div>
          <div class="list">
            <div class="item flex" v-for="item in twoList" :key="item.id">
              <div class="sign flex">
                <el-image :src="item.src" fit="contain"></el-image>
              </div>
              <p>{{ item.des }}</p>
              <i class="el-icon-arrow-down"></i>
            </div>
          </div>
        </section>
        <section class="three">
          <h3>企业文化</h3>
          <div class="cnt">
            <div class="box">
              <div class="bg"></div>
              <el-image :src="carture" fit="cover"></el-image>
            </div>
            <div class="modal bb">
              <div class="item" v-for="item in threeList" :key="item.id">
                <p>{{ item.title }}</p>
                <p class="sub">{{ item.subtitle }}</p>
              </div>
            </div>
          </div>
        </section>
      </template>
      <template v-if="$store.state.isPc">
        <!-- 发展历程 -->
        <section class="dev flex">
          <el-image :src="devbg" fit="cover"></el-image>
          <div class="progress flex">
            <h4>发展历程</h4>
            <div class="progress-bar flex">
              <span class="time">2022</span>
              <span class="time">2021</span>
              <span class="time">2020</span>
            </div>
            <div class="bar flex">
              <span class="dot"></span>
              <span class="line"></span>
              <span class="dot"></span>
              <span class="line"></span>
              <span class="dot"></span>
            </div>
          </div>
        </section>
        <section class="dev-detail flex">
          <div class="box" v-for="item in progressList" :key="item.id">
            <h4>{{ item.year }}</h4>
            <div class="list flex" v-for="i in item.progress" :key="i.id">
              <span>{{ i.month }}</span>
              <p v-html="i.text"></p>
            </div>
          </div>
        </section>
        <!-- 业务架构 -->
        <section class="skel">
          <div class="box">
            <h4>业务架构</h4>
            <div class="skel-main">
              <el-image :src="skel1" class="skel-img1" fit="cover"></el-image>
              <el-image :src="skel2" class="skel-img2" fit="cover"></el-image>
              <div class="list flex">
                <div
                  class="item flex"
                  :class="[show == item.id ? 'active' : '']"
                  v-for="item in skel"
                  :key="item.id"
                  @click="show = item.id"
                >
                  <el-image
                    class="name"
                    :src="item.src"
                    fit="scale-down"
                  ></el-image>
                  <p>{{ item.text }}</p>
                </div>
              </div>
            </div>
            <div class="area flex">
              <el-image
                class="arr"
                :class="'arr' + show"
                :src="arrow"
              ></el-image>
              <p v-show="show == 1">
                我们致力于为用户提供高品质服务与创新性补能方案，升级用户出行体验，打造一个充满活力的社区，并与用户共同成长。
              </p>
              <p v-show="show == 2">
                推进储能侧深化融入传统企业，依靠能源管理服务系统，帮助企业及用户实现能源数字化，数字价值化。不断升级储能方案，联动不同产业积极加入储能建设，构建智慧储能新生态。
              </p>
              <p v-show="show == 3">
                推进储能侧深化融入传统企业，依靠能源管理服务系统，帮助企业及用户实现能源数字化，数字价值化。不断升级储能方案，联动不同产业积极加入储能建设，构建智慧储能新生态。
              </p>
              <p v-show="show == 4">
                构建产业物联网+的智能生态环境，依托云端、人工智能等技术创新，打造物联网产业升级方案。打通全产业链的场景与资源，助力工业、医疗、教育、交通等产业数字化升级，协助企业为用户提供智能服务，拉紧了用户与商业的智慧产业共同纽带。
              </p>
              <p v-show="show == 5">
                全系列充电桩、智能家居、储能产品生产制造。
              </p>
              <p v-show="show == 6">
                开展清洁能源、绿色建筑、零碳交通等技术研究，同时作为专业技术支持平台，为各企业各部门提供战略规划、市场公关、难点研发等专业支持。
              </p>
            </div>
          </div>
        </section>
        <AssignCard :bgSrc="carture">
          <div class="box flex">
            <h4 class="h4">企业文化</h4>
            <div class="list">
              <h5>务实｜创新</h5>
              <small>在破旧立新中开拓进取</small>
            </div>
            <div class="list">
              <h5>使命｜坚持</h5>
              <small>肩负使命，不负韶华</small>
            </div>
            <div class="list">
              <h5>沟通｜信任</h5>
              <small>奠定一切合作的基石</small>
            </div>
            <div class="list">
              <h5>透明｜开放</h5>
              <small>制度透明，团队透明，工作透明</small>
            </div>
          </div>
        </AssignCard>
      </template>
    </template>
  </Layout>
</template>

<script>
  import Swiper from "swiper";
  import "swiper/css/swiper.min.css";
  import { Component, Vue } from "vue-property-decorator";
  import Layout from "@/components/Layout.vue"; // @ is an alias to /src
  import AssignCard from "@/components/common/AssignCard.vue";
  import PicWall from "@/components/common/PicWall.vue";
  import MainSlogan from "@/components/common/MainSlogan.vue";
  import Applyin from "@/components/common/Applyin.vue";

  @Component({
    components: {
      Layout,
      AssignCard,
      PicWall,
      MainSlogan,
      Applyin,
    },
  })
  export default class Investment extends Vue {
    data() {
      return {
        bg: require("@/assets/aboutnew/img/bg.png"),
        devbg: require("@/assets/aboutnew/img/devbg.png"),
        skel1: require("@/assets/logo-gray.png"),
        skel2: require("@/assets/aboutnew/img/skel.png"),
        arrow: require("@/assets/aboutnew/img/arrow.png"),
        carture: require("@/assets/aboutnew/img/carture.png"),
        one1: require("@/assets/aboutnew/img/a1.png"),
        one2: require("@/assets/aboutnew/img/a9.png"),
        active: "",
        progressList: [
          {
            id: 1,
            year: "2022",
            progress: [
              {
                id: 11,
                month: "3月",
                text: "誉照建设的充电基础设施累计充电约66,604KWh<br/>累计出行里程约39万公里",
              },
              {
                id: 12,
                month: "5月",
                text: "累计CO₂减排约90吨，相当于植树9,000棵",
              },
            ],
          },
          {
            id: 2,
            year: "2021",
            progress: [
              {
                id: 22,
                month: "5月",
                text: "誉照科技成立",
              },
              {
                id: 23,
                month: "7月",
                text: "战略布局光伏发电领域",
              },
              {
                id: 24,
                month: "8月",
                text: "誉照铺设光伏组件总装机量超2,000KW<br/>累计发电1,020,000KWh<br />减少碳排放1,016,940kg，相当于植树101,694棵",
              },
              {
                id: 25,
                month: "10月",
                text: "成立全资子公司誉照智慧充电",
              },
              {
                id: 26,
                month: "11月",
                text: "战略布局电动汽车充电领域",
              },
            ],
          },
          {
            id: 3,
            year: "2020",
            progress: [
              {
                id: 33,
                month: "12月",
                text: "上饶君扬誉照新能源科技有限公司成立",
              },
            ],
          },
        ],
        skel: [
          {
            id: 1,
            src: require("@/assets/aboutnew/img/skel1.png"),
            text: "充电生态事业群",
          },
          {
            id: 2,
            src: require("@/assets/aboutnew/img/skel2.png"),
            text: "绿色能源投资事业群",
          },
          {
            id: 3,
            src: require("@/assets/aboutnew/img/skel3.png"),
            text: "智慧储能事业群",
          },
          {
            id: 4,
            src: require("@/assets/aboutnew/img/skel4.png"),
            text: "智能物联事业群",
          },
          {
            id: 5,
            src: require("@/assets/aboutnew/img/skel5.png"),
            text: "硬件智造事业群",
          },
          {
            id: 6,
            src: require("@/assets/aboutnew/img/skel6.png"),
            text: "零碳技术与发展研究院",
          },
        ],
        twoList: [
          {
            id: 1,
            src: require("@/assets/aboutnew/img/a3.png"),
            des: "充电生态事业群",
          },
          {
            id: 2,
            src: require("@/assets/aboutnew/img/a4.png"),
            des: "绿色能源投资事业群",
          },
          {
            id: 3,
            src: require("@/assets/aboutnew/img/a5.png"),
            des: "智慧储能事业群",
          },
          {
            id: 4,
            src: require("@/assets/aboutnew/img/a6.png"),
            des: "智能物联事业群",
          },
          {
            id: 5,
            src: require("@/assets/aboutnew/img/a7.png"),
            des: "硬件智造事业群",
          },
          {
            id: 6,
            src: require("@/assets/aboutnew/img/a8.png"),
            des: "零碳技术与发展研究院",
          },
        ],
        threeList: [
          {
            id: 1,
            title: "务实 | 创新",
            subtitle: "在破旧立新中开拓进取",
          },
          {
            id: 2,
            title: "使命 | 坚持",
            subtitle: "肩负使命，不负韶华",
          },
          {
            id: 3,
            title: "沟通 | 信任",
            subtitle: "奠定一切合作的基石",
          },
          {
            id: 4,
            title: "透明 | 开放",
            subtitle: "制度透明，团队透明，工作透明",
          },
        ],
        show: 1,
      };
    }
    mounted() {
      this.$nextTick(() => {
        new Swiper(".swiper-friends", {
          // autoplay: {
          //   delay: 5000,
          // },
          slidesPerView: 3,
          spaceBetween: 20,
          autoplay: false,
          navigation: {
            nextEl: ".custom-swiper-button-next",
            prevEl: ".custom-swiper-button-prev",
          },
          loop: false,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
        });
      });
    }
  }
</script>

<style lang="less" scoped>
  /deep/.section-main-slogan {
    > p {
      font-size: 28px;
      line-height: 32px;
      color: #333;
    }
    .tips {
      width: 100%;
      margin-top: 20px;
      > p {
        width: 100%;
        max-width: 1201px;
        text-align: center;
        font-size: 20px;
        line-height: 32px;
        color: #666666;
        margin: 0 auto;
      }
    }
  }
  .wish {
    width: 100%;
    max-width: 1200px;
    background: #f6f6f6;
    margin: 80px auto 0;
    .box {
      // width: 100%;
      // height: 100%;
      background: url(~@/assets/aboutnew/img/wishbg.png) 100% 100%;
      padding: 9% 5.75% 5.91% 9.16%;
      .left {
        width: 60.72%;
        font-size: 48px;
        color: #333;
        text-align: left;
        border-right: 1px solid #8e8e8f;
        margin: 0 44px 0 0;
      }
      // .line {
      //     width: 1px;
      //     height: 100%;
      //     background: #8E8E8F;
      // }
      .right {
        flex-direction: column;
        flex: 1;
        text-align: left;
        > span {
          font-size: 28px;
          line-height: 32px;
          color: #333;
          margin-bottom: 5%;
        }
        > p {
          font-size: 24px;
          line-height: 50px;
          color: #666666;
          text-align: left;
        }
      }
    }
  }
  .dev {
    width: 100%;
    height: 420px;
    position: relative;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
    .el-image {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
    .progress {
      position: relative;
      z-index: 2;
      flex-direction: column;
      color: #ffffff;
      h4 {
        font-size: 48px;
        line-height: 48px;
        margin-bottom: 32px;
        margin-top: 0;
      }
      .progress-bar {
        position: relative;
        width: 420px;
        justify-content: space-between;
        // padding-bottom: 24px;
        .time {
          font-size: 14px;
          position: relative;
          // &::after {
          //     position: absolute;
          //     z-index: 2;
          //     content: '';
          //     bottom: 0;
          //     left: 0;
          //     width: 24px;
          //     height: 24px;
          //     background: rgba(255, 255, 255, 0.5);
          //     border-radius: 50%;
          // }
        }

        // &::before {
        //     content: '';
        //     position: absolute;
        //     width: 98%;
        //     height: 1px;
        //     background: rgba(255, 255, 255, 0.5);
        //     bottom: 0;
        //     left: 0;
        //     right: 0;
        //     margin: 0 auto;
        //     z-index: 1;
        // }
      }
      .bar {
        width: 420px;
        align-items: center;
        margin-top: 12px;
        .dot {
          width: 24px;
          height: 24px;
          background: rgba(255, 255, 255, 0.5);
          border-radius: 50%;
        }
        .line {
          width: 176px;
          height: 1px;
          background: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
  .dev-detail {
    width: 100%;
    max-width: 1474px;
    justify-content: space-between;
    overflow: auto;
    margin: 0 auto 80px;
    .box {
      width: 421px;
      h4 {
        width: 100%;
        text-align: left;
        font-size: 36px;
        line-height: 48px;
        color: #333;
        border-bottom: 1px solid #d8d8d8;
        padding-bottom: 38px;
        margin-bottom: 42px;
      }
      .list {
        font-size: 18px;
        line-height: 42px;
        color: #3d3d3d;
        align-items: flex-start;
        span {
          width: 38px;
          margin-right: 8px;
        }
        p {
          text-align: left;
          margin: 0;
        }
      }
    }
  }
  .skel {
    width: 100%;
    // height: 100vh;
    background: #f9f9f9;
    .box {
      width: 100%;
      max-width: 1200px;
      overflow: auto;
      margin: 0 auto;
      h4 {
        font-size: 48px;
        line-height: 48px;
        color: #333;
        text-align: left;
        margin: 8.8% 0 6.16%;
      }
      .skel-main {
        width: 100%;
        text-align: center;
        .skel-img1 {
          width: 432px;
        }
        .skel-img2 {
          width: 1008px;
        }
        .list {
          width: 100%;
          justify-content: space-between;
          .item {
            width: 194px;
            height: 120px;
            background: #ebebeb;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            &.active {
              background: #ffffff;
            }
            .name {
              height: 26px;
            }
            p {
              font-size: 18px;
              line-height: 30px;
              color: #333;
              margin: 9px 0 0;
            }
          }
        }
      }
      .area {
        position: relative;
        width: 100%;
        height: 190px;
        background: #fff;
        justify-content: center;
        align-items: center;
        margin-top: 73px;
        margin-bottom: 156px;
        .arr {
          position: absolute;
          top: -39px;
          left: 61px;
          width: 68px;
          height: 68px;
          &.arr2 {
            left: 260px;
          }
          &.arr3 {
            left: 466px;
          }
          &.arr4 {
            left: 666px;
          }
          &.arr5 {
            left: 866px;
          }
          &.arr6 {
            left: 1066px;
          }
        }
        p {
          font-size: 18px;
          line-height: 30px;
          color: #666666;
          text-align: left;
          margin: 0 89px;
        }
      }
    }
  }
  /deep/.section-assign {
    .left {
      .left-content {
        flex: 1;
        align-items: flex-end;
      }
    }
    .box {
      .h4 {
        width: 100%;
        margin-top: 64px;
        text-align: left;
        margin-bottom: 91px;
        // margin-right: 16.16%;
      }
      // width: 24.94%;
      text-align: left;
      flex-direction: column;
      // align-items: flex-end;
      margin-right: 137px;
      .list {
        margin-bottom: 41px;
        h5 {
          font-size: 30px;
          line-height: 36px;
          color: #333;
          margin-bottom: 20px;
          margin-top: 0;
        }
        small {
          font-size: 20px;
          line-height: 20px;
          color: #666;
        }
      }
    }
  }

  @media screen and(max-width: 1024px) {
    /deep/.top {
      height: 354rem;

      .modal {
        box-sizing: border-box;
        padding: 0 24rem;
        .box {
          .h4 {
            font-size: 22rem;
            margin-bottom: 20rem;
          }
          p {
            max-width: 327rem;
          }
          .bg-title {
            width: 100%;
            font-size: 11rem;
            line-height: 15rem;
          }
        }
      }
    }
    /deep/.section-main-slogan {
      h4 {
        font-size: 22rem;
        height: auto;
        line-height: 22rem;
        font-weight: normal;
        margin: 42rem auto 20rem;
      }
      p {
        width: 100%;
        font-size: 11rem;
        text-align: left;
        color: #474645;
        box-sizing: border-box;
        font-weight: 500;
        margin: 0;
        padding: 0 24rem;
      }
      .tips > p {
        text-align: left;
        box-sizing: border-box;
        font-size: 11rem;
        color: #474645;
      }
    }
    .wish {
      margin-top: 60rem;
      .box {
        .left {
          width: 154rem;
          font-size: 22rem;
          color: #1a1311;
          margin-right: 15rem;
        }
        .right {
          > span {
            font-size: 14rem;
            font-weight: 500;
          }
          p {
            font-size: 12rem;
          }
        }
      }
    }
    .one {
      .el-image {
        height: auto;
      }
    }
    .two {
      background-color: #ebebeb;
      padding: 39rem 24rem 32rem;
      h3 {
        font-size: 22rem;
        margin-bottom: 40rem;
      }
      .img {
        width: 100%;
        justify-content: center;
        margin-bottom: 18rem;
        .el-image {
          width: 242rem;
        }
      }
      .list {
        .item {
          position: relative;
          background-color: #ffffff;
          padding: 11rem 16rem;
          margin-bottom: 5rem;
          .sign {
            width: 82rem;
            justify-content: flex-start;
            align-items: center;
            .el-image {
              width: auto;
              height: 23rem;
            }
          }
          p {
            font-size: 11rem;
          }
          .el-icon-arrow-down {
            position: absolute;
            font-size: 11rem;
            top: 50%;
            transform: translateY(-50%);
            right: 16rem;
          }
        }
      }
    }
    .three {
      h3 {
        font-size: 22rem;
        text-align: left;
        padding: 32rem 24rem 16rem;
        margin: 0;
      }
      .cnt {
        width: 100%;
        height: 246rem;
        position: relative;
        .box {
          width: 100%;
          height: 100%;
          .bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 99;
          }
        }
        .modal {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 100;
          padding: 16rem 24rem;
          p {
            font-size: 11rem;
            color: #ffffff;
            text-align: left;
            margin: 0 0 6rem;
            &.sub {
              margin-bottom: 22rem;
            }
          }
        }
      }
    }
  }
</style>
